import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';

const ChatContext = createContext(null);

export function ChatProvider({ children }) {
  const [messages, setMessages] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [images, setImages] = useState([]);
  const [reports, setReports] = useState([]);
  const [bannedUsers, setBannedUsers] = useState([]);

  // Memoize all callback functions
  const addMessage = useCallback((message) => {
    setMessages(prev => [...prev, message]);
  }, []);

  const removeMessage = useCallback((messageId) => {
    setMessages(prev => prev.filter(msg => msg.messageId !== messageId));
  }, []);

  const updateMessage = useCallback((messageId, content ) => {
    setMessages(prev => prev.map(msg => msg.messageId === messageId ? { ...msg, content } : msg));
  }, []);

  const addHistoryMessages = useCallback((messages) => {
    setMessages(prev => [messages, ...prev]);
  }, []);

  const addReaction = useCallback(({ messageId, sender, reaction }) => {
    setReactions(prev => [...prev, { messageId, sender, reaction }]);
  }, []);

  const addReport = useCallback(({ messageId, sender, url }) => {
    setReports(prev => [...prev, { messageId, sender, url }]);
  }, []);

  const cancelReport = useCallback(({ messageId, sender, url }) => {
    setReports(prev => prev.filter(r => !(r.messageId === messageId && r.sender === sender && r.url === url)));
  }, []);

  const ignoreReport = useCallback(({ messageId, url }) => {
    setReports(prev => prev.filter(r => !(r.messageId === messageId && r.url === url)));
  }, []);

  const removeReaction = useCallback(({ messageId, sender, reaction }) => {
    setReactions(prev => 
      prev.filter(r => 
        !(r.messageId === messageId && r.sender === sender && r.reaction === reaction)
      )
    );
  }, []);

  const updateReaction = useCallback(({ messageId, sender, reaction }) => {
    setReactions(prev => {
      const filtered = prev.filter(r => 
        !(r.messageId === messageId && r.sender === sender)
      );
      return [...filtered, { messageId, sender, reaction }];
    });
  }, []);

  const addHistoryReaction = useCallback(({ messageId, sender, reaction }) => {
    setReactions(prev => [{ messageId, sender, reaction }, ...prev]);
  }, []);

  const getMessageReactions = useCallback((messageId) => {
    return reactions.filter(r => r.messageId === messageId);
  }, [reactions]);

  const getMessageReports = useCallback((messageId) => {
    return reports.filter(r => r.messageId === messageId);
  }, [reports]);

  const addImage = useCallback((image) => {
    setImages(prev => [...prev, image]);
  }, []);

  const addBannedUser = useCallback((user) => {
    setBannedUsers(prev => [...prev, user]);
  }, []);

  const removeBannedUser = useCallback((user) => {
    setBannedUsers(prev => prev.filter(u => u !== user));
  }, []);

  const clearChatHistory = useCallback(() => {
    setMessages([]);
    setReactions([]);
    setReports([]);
    setImages([]);
    setBannedUsers([]);
  }, []);

  // Memoize the context value
  const value = useMemo(() => ({
    messages,
    reactions,
    images,
    bannedUsers,
    reports,
    addMessage,
    removeMessage,
    updateMessage,
    addHistoryMessages,
    addReaction,
    removeReaction,
    updateReaction,
    addReport,
    cancelReport,
    ignoreReport,
    addHistoryReaction,
    getMessageReactions,
    getMessageReports,
    addImage,
    addBannedUser,
    removeBannedUser,
    clearChatHistory,
  }), [
    messages,
    reactions,
    images,
    bannedUsers,
    reports,
    addMessage,
    removeMessage,
    updateMessage,
    addHistoryMessages,
    addReaction,
    removeReaction,
    updateReaction,
    addReport,
    cancelReport,
    ignoreReport,
    addHistoryReaction,
    getMessageReactions,
    getMessageReports,
    addImage,
    addBannedUser,
    removeBannedUser,
    clearChatHistory,
  ]);

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
}

export const useChatList = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatList must be used within a ChatProvider');
  }
  return context;
}; 