import { toast } from 'react-toastify';
import { useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';

export const useToast = () => {
  const showNotification = useCallback((message, type = 'default') => {
    switch (type) {
      case 'error':
        toast.error(message);
        break;
      case 'success':
        toast.success(message);
        break;
      case 'warning':
        toast.warning(message);
        break;
      default:
        toast(message);
    }
  }, []); // Empty dependency array since toast functions are stable

  return { showNotification };
};