import React from 'react';
import VideoPlayer from './VideoPlayer';
import { useParams } from 'react-router-dom';


const MessageItem = ({ message, reactions, onReaction, onImageClick, initialUsername, showEmojiPicker, reports, isModerator, handleBanUser, bannedUsers }) => {
    const formattedTime = new Date(message.timestamp.replace(' ', 'T') + 'Z').toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    const { slug } = useParams();
    const isOwner = message.sender === initialUsername;
    const isBanned = bannedUsers.some(user => user === message.sender);

    return (
        <>
            <li className="group relative rounded-lg pb-2 hover:bg-gray-700/30" data-message-id={message.messageId}>

                <div className="pl-2 text-sm font-bold text-purple-600 w-[120px] overflow-x-visible whitespace-nowrap"><span className="text-gray-400">{formattedTime}</span> <span className={isBanned ? "text-red-600" : ""}>{message.sender}</span></div>

                <div className="pl-6 flex flex-col">
                    <div className="text-sm">
                        {message.type === 'file' ? (
                            <>
                                {message.content.split(',').map((file, index) => {
                                    const fileExtension = file.split('.').pop().toLowerCase();
                                    const isImage = ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension);
                                    const isReported = reports.some(report => report.url === file && (report.sender === initialUsername || isModerator));

                                    return (
                                        <React.Fragment key={index}>
                                            {isImage ? (
                                                <div className="mb-2 inline-block pr-2">
                                                    <div className="max-w-sm relative">
                                                        <img
                                                            src={file}
                                                            alt="Preview"
                                                            className={`rounded-lg cursor-pointer hover:opacity-90 w-[120px] h-[120px] object-cover ${isReported ? 'border-2 border-red-700' : ''}`}
                                                            onClick={() => onImageClick && onImageClick(file, isReported, isOwner)}
                                                        />
                                                        {isReported && (
                                                            <div className="absolute bottom-1 right-1 rounded-full w-5 h-5 flex items-center justify-center text-xs">
                                                                ⚠️
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : fileExtension === 'webm' ? (
                                                <div key={index} className="mb-2 block pr-2">
                                                    <div className="block max-w-[400px] w-full">
                                                        <div className="aspect-video relative">
                                                            <VideoPlayer
                                                                src={file}
                                                                className="rounded-lg w-full h-full"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div key={index} className="mb-2 block pr-2">
                                                    <a
                                                        href={file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="flex items-center gap-2 text-blue-400 hover:text-blue-300"
                                                    >
                                                        📎 {file.split('/').pop()}
                                                    </a>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </>
                        ) : (
                            message.content
                        )}
                    </div>

                    <div className="flex flex-wrap gap-1 mt-1">
                        {Object.entries(
                            reactions.reduce((acc, curr) => {
                                acc[curr.reaction] = (acc[curr.reaction] || 0) + 1;
                                return acc;
                            }, {})
                        ).map(([emoji, count]) => (
                            <div
                                key={emoji}
                                className="bg-gray-700 rounded-full px-2 py-1 text-sm flex items-center gap-1"
                            >
                                {emoji} <span>{count}</span>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="hidden group-hover:flex items-center gap-2 absolute right-4 top-4 bg-gray-700 rounded-full px-2 py-1">
                    <button
                        className="text-gray-400 hover:text-gray-200 text-sm"
                        onClick={(e) => {
                            e.stopPropagation();
                            const rect = e.target.getBoundingClientRect();
                            showEmojiPicker(rect, (emoji) => {
                                onReaction(emoji);
                            });
                        }}
                    >
                        😊
                    </button>
                    {isModerator && slug && !isOwner && (
                        <button
                            className="text-gray-400 hover:text-gray-200 text-sm"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleBanUser(message.sender, isBanned);
                            }}
                        >
                            {isBanned ? '✅' : '🚫'}
                        </button>
                    )}
                </div>
            </li>
            {message.hasmore === "0.0" && <br />}
        </>
    );
};

export default MessageItem; 