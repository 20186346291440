import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSidebar } from '../context/sidebarContext';
import { useWebSocket } from '../context/WebSocketContext';
import Modal from './Modal';
import { useAuth } from '../context/AuthContext';
import { useChatList } from '../context/ChatContext';
import { ConfirmModal } from './ConfirmModal';

const ImageGallery = React.memo(({ initialUsername }) => {
  const { roomInfo } = useSidebar();
  const [isImagesLoading, setIsImagesLoading] = useState(true);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [images, setImages] = useState([]);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [offset, setOffset] = useState(0);
  const containerRef = useRef(null);
  const { subscribe, wsRef } = useWebSocket();
  const { user, isModerator } = useAuth();
  const [isReported, setIsReported] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmConfig, setConfirmConfig] = useState({ message: '', onConfirm: () => {} });
  const { getMessageReactions, reports, addReport, cancelReport, ignoreReport, removeMessage, updateMessage, addMessage, addReaction, removeReaction, updateReaction } = useChatList();

  const fetchImages = useCallback(async () => {
    setIsImagesLoading(true);
    const token = localStorage.getItem('authToken');
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/images?slug=${roomInfo.slug}&offset=${offset}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    setOffset(offset + data.groups.length)

    if (data.success && Array.isArray(data.groups)) {
      const processedImages = data.groups
        .flatMap(msg =>
          msg.image_urls.map(url => ({
            url,
            messageId: msg.id,
            sender: msg.user_name,
            timestamp: msg.timestamp
          }))
        )
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      
      setImages(prevImages => [...prevImages, ...processedImages]);
      setHasMoreImages(data.hasMore);
    } else {
      console.error('Unexpected data format:', data);
    }
    setIsImagesLoading(false);
  }, [roomInfo.slug, offset]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isImagesLoading && hasMoreImages) {
        fetchImages();
      }
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, [isImagesLoading, hasMoreImages, fetchImages]);

  useEffect(() => {
    const handleMessage = (data) => {
      switch (data.type) {
        case 'report':
          setSelectedImageIndex(null)
          if(data.actionType === 'report'){
            addReport(data);
          } else if(data.actionType === 'cancel'){
            cancelReport(data);
          } else if(data.actionType === 'ignore'){
            ignoreReport(data);
          }
          break;
        case 'removeMessage':
          setSelectedImageIndex(null)
          removeMessage(data.messageId);
          setImages(prevImages => prevImages.filter(img => !(img.messageId === data.messageId && img.url === data.url)));
          break;
        case 'updateMessage':
          setSelectedImageIndex(null)
          updateMessage(data.messageId, data.content);
          setImages(prevImages => prevImages.filter(img => !(img.messageId === data.messageId && img.url === data.url)));
          break;
        case 'reaction':
          if (data.actionType === 'add') {
            addReaction(data);
          } else if (data.actionType === 'remove') {
            removeReaction(data);
          } else if (data.actionType === 'update') {
            updateReaction(data);
          }
          break;
        case 'file':
          addMessage({
            type: data.type,
            messageId: data.messageId,
            sender: data.sender,
            content: data.content,
            timestamp: data.timestamp
          });
          const newImages = data.content.split(',').map(url => ({
            url,
            messageId: data.messageId,
            sender: data.sender,
            timestamp: data.timestamp
          }))
          setImages(prevImages => [...newImages, ...prevImages]);
          break;
        default:
          break;
      }
    };
    const unsubscribe = subscribe(['report', 'removeMessage', 'updateMessage', 'reaction', 'file'], handleMessage);
    return () => unsubscribe();
  }, [subscribe, addReport, cancelReport, ignoreReport, removeMessage, updateMessage, addMessage, addReaction, removeReaction, updateReaction]);

  const handlePrevious = () => {
    setSelectedImageIndex((prev) => {
      if (prev === null) return null;
      return prev > 0 ? prev - 1 : images.length - 1;
    });
  };

  const handleNext = () => {
    setSelectedImageIndex((prev) => {
      if (prev === null) return null;
      return prev < images.length - 1 ? prev + 1 : 0;
    });
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setIsReported(reports.some(report => report.url === images[index].url && (report.sender === initialUsername || isModerator)));
    setIsOwner(images[index].sender === initialUsername);
  };

  const handleReportImage = () => {
    if (wsRef.current) {
      const onConfirm = () => {
        try {
          const payload = {
            type: "report",
            messageId: images[selectedImageIndex].messageId,
            userId: user?.user?.id,
            url: images[selectedImageIndex].url,
            actionType: isReported ? (isModerator ? 'ignore' : 'cancel') : 'report'
          };
          wsRef.current.send(JSON.stringify(payload));
          setSelectedImageIndex(null);
        } catch (error) {
          console.error('Error sending reaction:', error);
        }
      };

      setConfirmConfig({
        message: `Are you sure you want to ${isReported ? (isModerator ? 'ignore' : 'cancel') : ''} report this image?`,
        onConfirm
      });
      setIsConfirmOpen(true);
    }
  };

  const handleDeleteImage = () => {
    if (wsRef.current) {
      const onConfirm = () => {
        try {
          const payload = {
            type: "delete",
            messageId: images[selectedImageIndex].messageId,
            userId: user?.user?.id,
            url: images[selectedImageIndex].url,
          };
          wsRef.current.send(JSON.stringify(payload));
          setSelectedImageIndex(null);
        } catch (error) {
          console.error('Error sending reaction:', error);
        }
      };

      setConfirmConfig({
        message: 'Are you sure you want to delete this image?',
        onConfirm
      });
      setIsConfirmOpen(true);
    }
  };


  const showEmojiPicker = (rect, onEmojiSelect) => {
    const picker = document.createElement("div");
    picker.className = 'absolute z-[300] bg-gray-800 rounded-lg shadow-lg';
    picker.style.left = `${rect.left}px`;
    picker.style.top = `${rect.bottom + 5}px`;

    const emojiList = ['👍', '❤️', '😂', '😮', '😢', '👏', '🔥', '🎉'];

    emojiList.forEach(emoji => {
      const button = document.createElement("button");
      button.className = 'p-2 hover:bg-gray-700 rounded-lg';
      button.innerHTML = emoji;
      button.onclick = () => {
        onEmojiSelect(emoji);
        document.body.removeChild(picker);
      };
      picker.appendChild(button);
    });

    document.body.appendChild(picker);

    const closeHandler = (e) => {
      if (!picker.contains(e.target)) {
        if (document.body.contains(picker)) {
          document.body.removeChild(picker);
        }
        document.removeEventListener('click', closeHandler);
      }
    };

    setTimeout(() => {
      document.addEventListener('click', closeHandler);
    }, 0);
  };

  const handleReaction = (messageId, emoji) => {
    if (wsRef.current) {
      const payload = {
        type: "reaction",
        messageId: messageId,
        reaction: emoji,
        name: initialUsername
      };
      wsRef.current.send(JSON.stringify(payload));
    }
  };

  return (
    <div className="bg-gray-900 p-4 sm:p-8 h-full flex flex-col rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg sm:text-xl font-semibold leading-7 text-white">Image Gallery</h2>
      </div>
      <ConfirmModal
        isOpen={isConfirmOpen}
        message={confirmConfig.message}
        onConfirm={() => {
          confirmConfig.onConfirm();
          setIsConfirmOpen(false);
        }}
        onCancel={() => setIsConfirmOpen(false)}
      />
      <div id="create_cafe" className="mt-4 sm:mt-8 flex-grow overflow-auto">
        <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-2 sm:gap-4">
          {images.map((image, index) => {
            const isReported = reports.some(report => report.url === image.url && (report.sender === initialUsername || isModerator));
            return (
              <div
                key={index}
                className="aspect-square relative cursor-pointer hover:opacity-90 transition-opacity"
                onClick={() => handleImageClick(index)}
              >
                {image.url.toLowerCase().endsWith('.webm') ? (
                  <video
                    src={image.url}
                    className={`absolute rounded-md inset-0 w-full h-full object-cover ${isReported ? 'border-2 border-red-700' : ''}`}
                    muted
                    loop
                    autoPlay
                    playsInline
                  />
                ) : (
                  <img
                    src={image.url}
                    alt={"preview"}
                    className={`absolute rounded-md inset-0 w-full h-full object-cover ${isReported ? 'border-2 border-red-700' : ''}`}
                  />
                )}
                {isReported && (
                  <div className="absolute bottom-[1px] right-[1px] rounded-full w-5 h-5 flex items-center justify-center text-sm">
                      ⚠️
                  </div>
                )}
              </div>
          )})}
          {images.length === 0 && (
            <div className="col-span-full text-white text-center text-sm sm:text-base py-8">
              No images found
            </div>
          )}
          <div ref={containerRef} className="h-4 w-full col-span-full" />
        </div>
        {isImagesLoading && (
            <div className="flex justify-center py-4">
              <div className="relative w-10 h-10"> {/* Updated container */}
                <svg 
                  className="animate-spin absolute inset-0 text-indigo-500" 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
          )}
      </div>

      <Modal
        isOpen={selectedImageIndex !== null}
        onClose={() => setSelectedImageIndex(null)}
        onReport={() => {
          handleReportImage()
        }}
        isReported={isReported}
        isOwner={isOwner}
        isModerator={isModerator}
        onDelete={() => {
          handleDeleteImage()
        }}
        size="xl"
      >
        <div className="flex flex-col items-center gap-4 w-full mx-auto p-2 sm:p-4">
          <div className="flex items-center justify-between w-full">
            <button
              onClick={handlePrevious}
              className="bg-black bg-opacity-50 text-white p-2 sm:p-4 rounded-lg hover:bg-opacity-75 min-w-[36px] sm:min-w-[48px]"
            >
              &#8249;
            </button>

            <div className="flex-1 flex flex-col items-center relative group px-2 sm:px-4">
              {selectedImageIndex !== null && (
                <>
                  {images[selectedImageIndex].url.toLowerCase().endsWith('.webm') ? (
                    <video
                      src={images[selectedImageIndex].url}
                      className="w-full max-w-[250px] sm:max-w-[800px] md:max-w-[1000px] h-auto max-h-[70vh] object-contain rounded-lg"
                      muted
                      loop
                      autoPlay
                      playsInline
                    />
                  ) : (
                    <img
                      src={images[selectedImageIndex].url}
                      alt=""
                      className="w-full max-w-[250px] sm:max-w-[800px] md:max-w-[1000px] h-auto max-h-[80vh] object-contain rounded-lg"
                    />
                  )}
                  
                  <div className="absolute top-2 right-2 sm:top-4 sm:right-4 opacity-0 group-hover:opacity-100 transition-opacity">
                    <button
                      className="bg-gray-800 bg-opacity-75 hover:bg-opacity-90 rounded-full p-1.5 sm:p-2 text-gray-200"
                      onClick={(e) => {
                        e.stopPropagation();
                        const rect = e.target.getBoundingClientRect();
                        showEmojiPicker(rect, (emoji) => {
                          handleReaction(images[selectedImageIndex].messageId, emoji);
                        });
                      }}
                    >
                      <span className="text-base sm:text-lg">😊</span>
                    </button>
                  </div>

                  <div className="flex flex-wrap gap-1 mt-2">
                    {images[selectedImageIndex].messageId && Object.entries(
                      (getMessageReactions(images[selectedImageIndex].messageId) || [])
                        .reduce((acc, curr) => {
                          acc[curr.reaction] = (acc[curr.reaction] || 0) + 1;
                          return acc;
                        }, {})
                    ).map(([emoji, count]) => (
                      <div
                        key={emoji}
                        className="bg-gray-700 rounded-full px-2 py-1 text-xs sm:text-sm flex items-center gap-1"
                      >
                        {emoji} <span>{count}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>

            <button
              onClick={handleNext}
              className="bg-black bg-opacity-50 text-white p-2 sm:p-4 rounded-lg hover:bg-opacity-75 min-w-[36px] sm:min-w-[48px]"
            >
              &#8250;
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default ImageGallery;