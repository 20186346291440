import React from 'react';
import Turnstile from 'react-turnstile';

const TurnstileChallenge = ({ onVerify, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-95">
      <div className="bg-gray-700 p-6 rounded-lg shadow-lg w-[350px]">
        {/* <h2 className="text-xl font-semibold text-white mb-4">Verify you're human</h2> */}
        <img 
          src="https://cdn.thewatercooler.cafe/thumbnail.webp" 
          alt="Logo" 
          className="h-16 w-auto mx-auto block mb-2"
        />
        <Turnstile
          sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
          onVerify={onVerify}
          theme="dark"
          refreshExpired="auto"
        />
        <button 
          onClick={onClose}
          className="text-gray-400 hover:text-white mt-4 float-right"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TurnstileChallenge;