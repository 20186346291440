import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCategory } from '../context/CategoryContext';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const FormComponent = () => {
  const { user } = useAuth();
  const [cafes, setCafes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { categories, updateCategories } = useCategory();
  const { category } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
    description: '',
    category: categories[0]?.category_name || 'misc',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Automatically update Slug when Name is changed
    if (name === 'name') {
      const formattedSlug = value
        .toLowerCase()
        .replace(/\s+/g, '') // Replace spaces with underscores
        .replace(/[^a-z0-9_]/g, ''); // Remove special characters, keep alphanumeric and underscores
      setFormData((prevState) => ({
        ...prevState,
        slug: formattedSlug,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/cafes?slug=${encodeURIComponent(
      formData.slug
    )}&name=${encodeURIComponent(formData.name)}&description=${encodeURIComponent(
      formData.description
    )}&category=${formData.category === 'all' ? categories[0].category_name : formData.category}&is_private=0&status=active&user_id=${user?.user?.id}`;

    const dataToSend = {
      ...formData,
      user_id: user?.user?.id,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        console.log('Form submitted successfully');
        fetchCafes();
        setFormData({
          name: '',
          slug: '',
          description: '',
          category: categories[0].category_name || 'misc',
        });
        setIsModalOpen(false);
      } else {
        console.error('Error submitting form', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  const fetchCafes = useCallback(async (currentOffset = 0) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/cafes?limit=${limit}&offset=${currentOffset}${category && category !== 'all' ? `&category=${category}` : ''
        }`
      );
      const data = await response.json();

      if (data && Array.isArray(data)) {
        setCafes(data);
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching cafes:', error);
    } finally {
      setIsLoading(false);
    }
  }, [limit, category]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/categories`);
        
        updateCategories(categoriesResponse.data.results);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCafes(offset);
  }, [fetchCafes, offset, category]);

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      category: category
    }));
  }, [category]);

  const handleNext = () => {
    setOffset(offset + limit);
  };

  const handlePrevious = () => {
    setOffset(Math.max(0, offset - limit));
  };

  return (
    <div className="bg-gray-900 h-screen pt-16 flex flex-col">
      {/* Header Section */}
      <div className="bg-gray-800 p-4 px-8 md:p-6 border-b border-gray-700 sticky top-0 z-10 " style={{paddingInline: "6rem"}}>
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h2 className="text-lg md:text-xl font-semibold text-white">Created Cafes</h2>
          <button
            onClick={() => setIsModalOpen(true)}
            className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            New Cafe
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-4 md:p-6 overflow-auto">
        <div id="create_cafe" className="max-w-7xl mx-auto">
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <div className="flex flex-col items-center space-y-4">
                <svg className="animate-spin h-10 w-10 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span className="text-white">Loading cafes...</span>
              </div>
            </div>
          ) : (
            <div className="">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">Name</th>
                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-white">Slug</th>
                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-white">Description</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">Category</th>
                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-white">Private</th>
                    <th scope="col" className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-white">Created</th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">Status</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {cafes.map((cafe) => (
                    <tr key={cafe.id} className="hover:bg-gray-800">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                        <Link to={`/c/${cafe.slug}`} className="hover:text-indigo-400">
                          {cafe.name}
                        </Link>
                      </td>
                      <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-300">/c/{cafe.slug}</td>
                      <td className="hidden md:table-cell px-3 py-4 text-sm text-gray-300 truncate max-w-xs">{cafe.description}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{cafe.category}</td>
                      <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-300">{cafe.is_private ? 'Yes' : 'No'}</td>
                      <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                        {new Date(cafe.created_at).toLocaleDateString()}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{cafe.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      <div className="bg-gray-800 p-4 border-t border-gray-700 sticky bottom-0">
        <div className="max-w-7xl mx-auto flex justify-end gap-2">
          <button
            onClick={handlePrevious}
            disabled={offset === 0 || isLoading}
            className="px-4 py-2 bg-gray-700 text-white rounded disabled:opacity-50 hover:bg-gray-600"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            disabled={isLoading}
            className="px-4 py-2 bg-gray-700 text-white rounded disabled:opacity-50 hover:bg-gray-600"
          >
            Next
          </button>
        </div>
      </div>

      {/* Modal - Keep existing modal code but update the max-width for better mobile display */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
          <div className="fixed inset-0 bg-black bg-opacity-75"></div>
          <div className="bg-gray-800 rounded-lg p-4 md:p-6 z-50 w-full max-w-lg mx-auto relative">
            <h2 className="text-xl font-semibold leading-7 text-white mb-4">Create Cafe</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="slug" className="block text-sm font-medium leading-6 text-white">
                    Slug
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        /c/
                      </span>
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        value={formData.slug}
                        onChange={handleChange}
                        required
                        className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <label htmlFor="description" className="block text-sm font-medium leading-6 text-white">
                    Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      value={formData.description}
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="category" className="block text-sm font-medium leading-6 text-white">
                    Category
                  </label>
                  <div className="mt-2">
                    <select
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      required
                      disabled={categories.length === 0}
                      className="block w-full rounded-md border-0 bg-[#2a3441] py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    >
                      {categories.length === 0 ? (
                        <option value="misc">Loading categories...</option>
                      ) : (
                        categories.map((category) => (
                          <option
                            key={category.id}
                            value={category.category_name.toLowerCase()}
                          >
                            {category.category_name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormComponent;
