import React, { useState } from 'react';
import Menu from './components/Menu';
import ChatRoom from './components/ChatRoom';
import ImageGallery from './components/ImageGallery';
import CreateCafe from './components/CreateCafe';
import Sidebar from './components/Sidebar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider, useAuth } from './context/AuthContext';
import Auth from './components/Auth';
import { SidebarProvider } from './context/sidebarContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { ChatProvider } from './context/ChatContext';
import { CategoryProvider } from './context/CategoryContext';
// import UserProfileDropdown from './components/UserProfileDropdown';
import Navbar from './components/Navbar';
import { AdminAuthProvider } from './context/AdminAuthContext';
import AdminLogin from './components/AdminLogin';
import AdminRoute from './components/AdminRoute';
import AdminPanel from './components/AdminPanel';


function App() {
  return (
    <WebSocketProvider>
      <SidebarProvider>
        <ChatProvider>
          <CategoryProvider>
            <Router>
              <div className="m-0 h-screen w-full bg-gray-800 p-0 text-gray-200">
                <Routes>
                    <Route path="/*" element={
                    <AuthProvider>
                      <>
                        <Navbar />
                        <MainLayout />
                      </>
                    </AuthProvider>
                  } />
                  <Route path="/auth" element={
                    <AuthProvider>
                      <Navbar />
                      <Auth />
                    </AuthProvider>
                  } />

                  <Route path="/admin/*" element={
                    <AdminAuthProvider>
                      <AdminRoute>
                        <AdminPanel />
                      </AdminRoute>
                    </AdminAuthProvider>
                  } />
                  <Route path="/admin/login" element={
                    <AdminAuthProvider>
                      <AdminLogin />
                    </AdminAuthProvider>
                  } />
                </Routes>
              </div>
            </Router>
          </CategoryProvider>
        </ChatProvider>
      </SidebarProvider>
    </WebSocketProvider>
  );
}

function MainLayout() {
  const { user, loading, login } = useAuth();
  // const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  // React.useEffect(() => {
  //   if (!loading && !user) {
  //     navigate('/auth');
  //   }
  // }, [user, loading, navigate]);
  React.useEffect(() => {
    const handleAnonymousAuth = async () => {
      if (loading) return;

      // Check if there's an existing token
      const existingToken = localStorage.getItem('authToken');
      if (existingToken) {
        return;
      }

      // Only create anonymous user if there's no existing token or it's expired
      if (!user) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/anonymous`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            mode: 'cors'
          });

          if (response.ok) {
            const data = await response.json();
            const userData = {
              user: data.user
            };
            await login(userData, data.token);
          }
        } catch (error) {
          console.error('Failed to create anonymous user:', error);
        }
      }
    };

    handleAnonymousAuth();
  }, [loading, user, login]);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-xl text-white">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex h-screen pt-16">
      {/* Mobile Menu Button */}
      <button 
        className={`md:hidden fixed top-4 left-4 z-50 p-2 rounded-lg bg-gray-700 ${showSidebar ? 'hidden' : ''}`}
        onClick={() => setShowMenu(!showMenu)}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>

      {/* User Profile Dropdown */}
      {/* <div className="fixed top-4 right-16 z-50 md:right-4">
        <UserProfileDropdown />
      </div> */}

      {/* Mobile Sidebar Button */}
      <button 
        className="md:hidden  fixed top-4 right-4 z-50 p-2 rounded-lg bg-gray-700"
        onClick={() => setShowSidebar(!showSidebar)}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </button>

      {/* Overlay for mobile */}
      {(showMenu || showSidebar) && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 md:hidden"
          onClick={() => {
            setShowMenu(false);
            setShowSidebar(false);
          }}
        />
      )}

      {/* Sliding Menu */}
      <div className={`fixed inset-y-0 left-0 transform ${showMenu ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out md:w-64 w-64 bg-gray-900 z-30`}>
        <Menu />
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        <Routes>
          <Route path="/" element={<ChatRoom initialUsername={user?.user?.username} />} />
          <Route path="/c/:slug" element={<ChatRoom initialUsername={user?.user?.username} />} />
          <Route path="/images/:slug" element={<ImageGallery initialUsername={user?.user?.username} />} />
          <Route path="/chat_rooms/:category" element={<CreateCafe />} />
        </Routes>
      </div>

      {/* Sliding Sidebar */}
      <div className={`fixed inset-y-0 right-0 transform ${showSidebar ? 'translate-x-0 w-full md:w-64' : 'translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out md:w-64 bg-gray-900 z-30`}>
        <Sidebar initialUsername={user?.user?.username} />
      </div>
    </div>
  );
}

export default App;