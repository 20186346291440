import React, { useRef, useState, useEffect } from 'react';
import Modal from './Modal';
import Alert from './Alert';

const FileUpload = ({ onFileUpload = () => { }, acceptedFiles, initialUsername, disabled }) => {
  const fileInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  // const [fileCaption, setFileCaption] = useState('');
  const [alert, setAlert] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const validateFile = (file) => {
    // Maximum file size (50MB)
    const maxSize = 50 * 1024 * 1024;
    
    // Allowed file types
    const allowedTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/webp',
      'image/gif',
      'image/svg+xml',
      'image/bmp',
      'video/webm'
    ];

    if (file.size > maxSize) {
      setAlert({
        message: 'File size must be less than 50MB',
        type: 'error'
      });
      return false;
    }

    if (!allowedTypes.includes(file.type)) {
      setAlert({
        message: 'Only JPG, JPEG, PNG, WEBP, WEBM, GIF, SVG, and BMP files are allowed',
        type: 'error'
      });
      return false;
    }

    return true;
  };

  const uploadToR2 = async (file) => {
    try {
      // Convert file to base64
      const buffer = await file.arrayBuffer();
      const base64 = btoa(
        new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
      );

      // Get presigned URL from backend with file data
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/fileUpload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: file.name,
          fileType: file.type,
          fileBuffer: base64
        })
      });

      if (!response.ok) {
        throw new Error('Failed to get upload URL');
      }

      return await response.json();
    } catch (error) {
      console.error('Error uploading to R2:', error);
      throw error;
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 5) {
      setAlert({
        message: 'You can only upload up to 5 files at once',
        type: 'error'
      });
      return;
    }
    const validFiles = files.filter(validateFile);
    if (validFiles.length > 0) {
      processFiles(validFiles);
    }
  };

  const processFiles = async (files) => {
    const filePromises = files.map(file => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({
            file,
            preview: file.type.startsWith('image/') ? reader.result : null,
            caption: '',
            type: file.type,
            name: file.name,
            size: file.size
          });
        };
        if (file.type.startsWith('image/')) {
          reader.readAsDataURL(file);
        } else {
          resolve({
            file,
            preview: null,
            caption: '',
            type: file.type,
            name: file.name,
            size: file.size
          });
        }
      });
    });

    const processedFiles = await Promise.all(filePromises);
    setSelectedFiles(processedFiles);
    setCurrentFileIndex(0);
    // setFileCaption('');
    setIsModalOpen(true);
  };

  const handleSend = async () => {
    if (selectedFiles.length === 0) return;

    setIsUploading(true);

    try {
      const fileUrls = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        const fileData = selectedFiles[i];
        
        // Upload file to R2 and get the URL
        const { url, key, fileName } = await uploadToR2(fileData.file);
        fileUrls.push({ url, key, fileName });
        if (i < selectedFiles.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }

      onFileUpload(fileUrls);
    } catch (error) {
      setAlert({
        message: 'Error uploading files. Please try again.',
        type: 'error'
      });
    } finally {
      setIsUploading(false);
      closeModal();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFiles([]);
    setCurrentFileIndex(0);
    // setFileCaption('');
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      if (acceptedFiles.length > 5) {
        setAlert({
          message: 'You can only upload up to 5 files at once',
          type: 'error'
        });
        return;
      }
      const validFiles = acceptedFiles.filter(validateFile);
      if (validFiles.length > 0) {
        processFiles(validFiles);
      }
    }
  }, [acceptedFiles]);

  return (
    <div>
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert(null)}
        />
      )}
      <span
        onClick={() => !disabled && fileInputRef.current?.click()}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer', display: 'inline-flex', alignItems: 'center' }}
        className={`text-3xl text-grey border-r-4 border-gray-600 bg-gray-600 p-2 ${disabled ? 'opacity-50' : ''}`}
      >
        <svg
          className={`h-6 w-6 block bg-gray-500 ${!disabled && 'hover:bg-gray-400'} rounded-xl`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M16 10c0 .553-.048 1-.601 1H11v4.399c0 .552-.447.601-1 .601-.553 0-1-.049-1-.601V11H4.601C4.049 11 4 10.553 4 10c0-.553.049-1 .601-1H9V4.601C9 4.048 9.447 4 10 4c.553 0 1 .048 1 .601V9h4.399c.553 0 .601.447.601 1z"
            fill="#FFFFFF"
          />
        </svg>
      </span>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        disabled={disabled}
        multiple
      />

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        submitText={isUploading ? "Uploading..." : "Send"}
        onSubmit={handleSend}
        disabled={isUploading}
      >
        <div className="space-y-3 sm:space-y-4 mx-auto max-w-[90vw] sm:max-w-lg">
          <h3 className="text-base sm:text-lg font-semibold text-white text-center">
            Send Files ({currentFileIndex + 1}/{selectedFiles.length})
          </h3>

          {selectedFiles[currentFileIndex] && (
            <div className="relative">
              <div className="flex flex-col items-center gap-2 sm:gap-4 w-full mx-auto">
                <div className="w-full bg-gray-700 rounded-lg p-4">
                  {selectedFiles[currentFileIndex].preview ? (
                    <img
                      src={selectedFiles[currentFileIndex].preview}
                      alt="Preview"
                      className="w-full max-w-[90vw] sm:max-w-[500px] h-auto max-h-[60vh] object-contain rounded-lg mx-auto"
                    />
                  ) : (
                    <div className="flex items-center justify-center p-4">
                      <div className="text-center">
                        <p className="text-white font-medium">{selectedFiles[currentFileIndex].name}</p>
                        <p className="text-gray-400 text-sm">
                          {formatFileSize(selectedFiles[currentFileIndex].size)}
                        </p>
                        <p className="text-gray-400 text-sm">
                          {selectedFiles[currentFileIndex].type}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default FileUpload;