import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminAuthContext = createContext(null);

export const AdminAuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkAdminAuth = useCallback(async () => {
    try {
      const token = localStorage.getItem('adminAuthToken');
      
      if (!token) {
        setAdmin(null);
        setLoading(false);
        navigate('/admin/login');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/me`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const adminData = await response.json();
        if (adminData.user.type !== 'admin') {
          throw new Error('Not authorized as admin');
        }
        setAdmin(adminData);
      } else {
        localStorage.removeItem('adminAuthToken');
        setAdmin(null);
        navigate('/admin/login');
      }
      setLoading(false);
    } catch (error) {
      console.error('Admin auth check failed:', error);
      localStorage.removeItem('adminAuthToken');
      setAdmin(null);
      navigate('/admin/login');
    }
  }, [navigate]);

  useEffect(() => {
    checkAdminAuth();
  }, [checkAdminAuth]);

  const adminLogin = async (credentials) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      });

      if (response.ok) {
        const { token, user } = await response.json();
        if (user.type !== 'admin') {
          throw new Error('Not authorized as admin');
        }
        localStorage.setItem('adminAuthToken', token);
        setAdmin({ user });
        navigate('/admin');
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      throw error;
    }
  };

  const adminLogout = () => {
    localStorage.removeItem('adminAuthToken');
    setAdmin(null);
    navigate('/admin/login');
  };

  return (
    <AdminAuthContext.Provider 
      value={{ 
        admin, 
        loading, 
        adminLogin, 
        adminLogout 
      }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => {
  const context = useContext(AdminAuthContext);
  if (context === null) {
    throw new Error('useAdminAuth must be used within an AdminAuthProvider');
  }
  return context;
};