import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModerator, setIsModerator] = useState(false);

  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const tokenExpiration = localStorage.getItem('tokenExpiration');

      if (!token || !tokenExpiration) {
        setUser(null);
        setLoading(false);
        return;
      }

      // Check if token is close to expiring (e.g., within 5 minutes)
      // const isNearExpiration = new Date().getTime() > Number(tokenExpiration) - (5 * 60 * 1000);

      // if (isNearExpiration) {
      //   // Call refresh token endpoint
      //   const refreshResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/refresh`, {
      //     method: 'POST',
      //     headers: {
      //       'Authorization': `Bearer ${token}`,
      //       'Content-Type': 'application/json'
      //     }
      //   });

      //   if (refreshResponse.ok) {
      //     const { newToken, expiresIn } = await refreshResponse.json();
      //     localStorage.setItem('authToken', newToken);
      //     localStorage.setItem('tokenExpiration', (new Date().getTime() + expiresIn * 1000).toString());
      //   } else {
      //     // Add this block to handle failed refresh
      //     localStorage.removeItem('authToken');
      //     localStorage.removeItem('tokenExpiration');
      //     setUser(null);
      //     setLoading(false);
      //     return;
      //   }
      // }

      // Continue with regular auth check
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/me`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
      } else {
        // Add this block to handle unauthorized/expired session
        localStorage.removeItem('authToken');
        localStorage.removeItem('tokenExpiration');
        setUser(null);
        navigate('/auth');
      }
      setLoading(false);
    } catch (error) {
      console.error('Auth check failed:', error);
      localStorage.removeItem('authToken');
      localStorage.removeItem('tokenExpiration');
      setUser(null);
      navigate('/auth');
    }
  };

  const updateModeratorStatus = (status) => {
    setIsModerator(status);
  };

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (user) {
        checkAuth();
      }
    }, 4 * 60 * 1000); // Check every 4 minutes

    return () => clearInterval(refreshInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const login = async (userData, token, expiresIn) => {
    if (!token) {
      console.error('No token received during login');
      return;
    }

    // Store token with expiration
    const expirationTime = new Date().getTime() + expiresIn * 1000;
    localStorage.setItem('authToken', token);
    localStorage.setItem('tokenExpiration', expirationTime.toString());

    setUser(userData);
    setLoading(false);
  };

  const logout = async () => {
    // try {
    //   const token = localStorage.getItem('authToken');
    //   await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/logout`, {
    //     method: 'POST',
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json'
    //     }
    //   });
    // } catch (error) {
    //   console.error('Logout failed:', error);
    // }
    localStorage.removeItem('authToken');
    setUser(null);
    navigate('/auth');
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout, isModerator, updateModeratorStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 