import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import UserProfileDropdown from './UserProfileDropdown';

const Navbar = () => {
  const { user } = useAuth();

  return (
    <nav className="bg-gray-900 fixed w-full shadow-lg z-50">
      <div className="mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
                <img 
                    src="https://cdn.thewatercooler.cafe/thumbnail.webp" 
                    alt="Logo" 
                    className="h-16 w-auto"
                    />
                <span className="text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-purple-500 text-2xl font-bold animate-gradient">
                    The Water Cooler
                </span>
            </Link>
          </div>

          <div className="flex items-center space-x-4">
            {user && !user.user?.isAnonymous ? (
              <UserProfileDropdown />
            ) : (
              <>
                {/* <Link
                  to="/auth"
                  className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Login
                </Link> */}
                <Link
                  to="/auth"
                  className="bg-indigo-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-600"
                >
                  Login
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;