import React, { createContext, useContext, useRef, useState, useCallback, useEffect } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const currentRoomRef = useRef(null);
  const hasLoadedHistoryRef = useRef(false);
  const messageHandlersRef = useRef(new Map());

  const [isConnected, setIsConnected] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('connecting');
  const [retryAttempt, setRetryAttempt] = useState(0);

  // Subscribe to message types
  const subscribe = useCallback((messageTypes, handler) => {
    messageTypes.forEach(type => {
      if (!messageHandlersRef.current.has(type)) {
        messageHandlersRef.current.set(type, new Set());
      }
      messageHandlersRef.current.get(type).add(handler);
    });

    // Return unsubscribe function
    return () => {
      messageTypes.forEach(type => {
        const handlers = messageHandlersRef.current.get(type);
        if (handlers) {
          handlers.delete(handler);
          if (handlers.size === 0) {
            messageHandlersRef.current.delete(type);
          }
        }
      });
    };
  }, []);

  const updateConnectionStatus = useCallback((status, attempt = 0) => {
    setConnectionStatus(status);
    setRetryAttempt(attempt);
  }, []);

  const connect = useCallback((url, username) => {
    // Clear any existing connection
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }

    try {
      const ws = new WebSocket(url);

      ws.onopen = () => {
        console.log('WebSocket connection opened');
        setIsConnected(true);
        updateConnectionStatus('connected');
        wsRef.current = ws;

        // Only send join message if this is a new room or first connection
        if (currentRoomRef.current !== url || !hasLoadedHistoryRef.current) {
          ws.send(JSON.stringify({
            type: "join",
            name: username,
            timestamp: Date.now()
          }));
          currentRoomRef.current = url;
        }
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          // Notify all handlers subscribed to this message type
          const handlers = messageHandlersRef.current.get(data.type);
          if (handlers) {
            handlers.forEach(handler => handler(data));
          }
        } catch (error) {
          console.error('Error parsing message:', error);
        }
      };

      ws.onclose = () => {
        // Only attempt reconnect if this is still the current connection
        if (wsRef.current === ws && retryAttempt < 5) {
          setIsConnected(false);
          const nextAttempt = retryAttempt + 1;
          const delay = Math.min(Math.pow(2, retryAttempt) * 1000, 30000);

          updateConnectionStatus(`Connection lost. Reconnecting in ${Math.round(delay / 1000)}s...`, nextAttempt);

          reconnectTimeoutRef.current = setTimeout(() => {
            connect(url, username);
          }, delay);
        } else if (retryAttempt >= 5) {
          updateConnectionStatus('Connection failed. Please refresh the page.');
        }
      };

      return ws;
    } catch (error) {
      console.error('WebSocket connection error:', error);
      updateConnectionStatus('Connection failed');
    }
  }, [retryAttempt, updateConnectionStatus]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    };
  }, []);

  const value = {
    wsRef,
    isConnected,
    connectionStatus,
    connect,
    subscribe,
    sendMessage: useCallback((message) => {
      if (wsRef.current && isConnected) {
        wsRef.current.send(JSON.stringify(message));
      }
    }, [isConnected])
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export default WebSocketProvider;